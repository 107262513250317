import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminUsers: (state: AdminState) => state.users,
    adminOneUser: (state: AdminState) => (userId: number) => {
        const filteredUsers = state.users.filter((user) => user.id === userId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
    readRoles: (state: AdminState) => state.roles,
    getRole: (state: AdminState) => state.current_role,
    getRoleName: (state: AdminState) => (roleId: number) => {
        for (let role of state.roles){
            console.log(role)
            if (role.id == roleId) {
                return role.name;
            }
        }
        console.log("lll")
        return '';
    }
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readRoles = read(getters.readRoles);
export const getRole = read(getters.getRole);
export const getRoleName = read(getters.getRoleName);